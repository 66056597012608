.nav-tabs {
    border: 0;
    
    .nav-item {
        .nav-link {
            border: 0;
            padding: 1rem;
            background: rgba($color: #0C2A56, $alpha: .1);
            &:not(.disabled) {
                color: inherit;
            }

            &.active {
                border-bottom: 2px solid $primary;
                background: rgba($color: $green, $alpha: .1);
            }
        }
    }
}

.tab-content {
    padding: 1rem;
}

.nav-center {
    .nav-tabs {
        justify-content: left !important;
        margin-bottom: 0rem;
    }
}

.p-0 {
    .tab-content {
        padding: 1rem 0;
    }
}